<template>
<loader :loading="loading">
    <columns>
        <column>
            <page-heading :heading="report.name"></page-heading>
        </column>
        <column class="is-narrow">
            <tabs>
                <tab-link name="report-manager" :params="{ report: report.id }">Overview</tab-link>
                <tab-link name="report-widgets" :params="{ report: report.id }">Widgets</tab-link>
                <tab-link name="edit-report" :params="{ report: report.id }">Edit</tab-link>
            </tabs>
        </column>
    </columns>
    <router-view />
</loader>
</template>
<script>
import { mapGetters } from 'vuex'

export default {

    data: () => ({
        loading: true
    }),

    async beforeCreate() {
        await this.$store.dispatch('report/load', this.$route.params.report)
        this.loading = false
    },

    beforeDestroy() {
        this.$store.commit('report/clearReport')
    },

    computed: {
        ...mapGetters('report', [
            'report'
        ])
    }

}
</script>